import {
  useEffect,
  useState,
} from 'react';

import { submitPartnerAgreement } from 'api';
import { DashboardOptions } from 'contexts';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from 'services';

import {
  PartnerFormABNStep,
  PartnerFormAddressStep,
  PartnerFormAdminStep,
  PartnerFormCompletedStep,
  PartnerFormContactsStep,
  PartnerFormInfoStep,
  PartnerFormLegalStep,
  PartnerFormStepLayout,
  PartnerFormTypeStep,
} from './steps';

const GET_INITIAL_PARTNER_STATE = () => ({
  mobile: { value: "", error: false },

  country: { value: "", error: false },
  ABN: { value: "", error: false },
  entityName: { value: "", error: false },
  entityType: { value: "", error: false },
  preferredName: { value: "", error: false },

  dashboardType: { value: "", error: false },

  ACN: { value: "", error: false },
  ANZSIC: { value: "", error: false },
  telephone: { value: "", error: false },
  industrySector: { value: "", error: false },
  website: { value: "https://", error: false },
  numEmployee: { value: "", error: false },

  headOfficeCountry: { value: "", error: false },
  headOfficeStreetName: { value: "", error: false },
  headOfficeSuburb: { value: "", error: false },
  headOfficeState: { value: "", error: false },
  headOfficePostcode: { value: "", error: false },
  sameAddresses: true,
  postalCountry: { value: "", error: false },
  postalStreetName: { value: "", error: false },
  postalSuburb: { value: "", error: false },
  postalState: { value: "", error: false },
  postalPostcode: { value: "", error: false },

  adminFirstName: { value: "", error: false },
  adminLastName: { value: "", error: false },
  adminRole: { value: "", error: false },
  adminEmail: { value: "", error: false, errorMsg: "" },
  adminPhone: { value: "", error: false },
  adminAuthMobile: { value: "", error: false },

  lockAdminEmail: false,

  sameRelManager: true,
  relManagerFirstName: { value: "", error: false },
  relManagerLastName: { value: "", error: false },
  relManagerEmail: { value: "", error: false },
  relManagerPhone: { value: "", error: false },
  sameBusManager: true,
  busManagerFirstName: { value: "", error: false },
  busManagerLastName: { value: "", error: false },
  busManagerEmail: { value: "", error: false },
  busManagerPhone: { value: "", error: false },
  sameRep: true,
  repFirstName: { value: "", error: false },
  repLastName: { value: "", error: false },
  repEmail: { value: "", error: false },
  repPhone: { value: "", error: false },
  repRole: { value: "", error: false },
  repAddressCountry: { value: "", error: false },
  repAddressStreetName: { value: "", error: false },
  repAddressSuburb: { value: "", error: false },
  repAddressState: { value: "", error: false },
  repAddressPostcode: { value: "", error: false },
});

const STEPS = [
  "Partner Type",
  "Legal Details",
  "Organisation Details",
  "Organisation Address",
  "Admin User Details",
  "Key Contacts",
];

export function PartnerFormPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [partnerState, setPartnerState] = useState(GET_INITIAL_PARTNER_STATE());
  const [capReferral, setCapReferral] = useState(null);

  const { state } = useLocation();

  const handleInput = (event) => {
    // Figure out value to set first
    let value;
    if (event?.target?.name === "ABN") {
      // No spaces in ABN
      value = event?.target?.value.replace(/ /g, "");
    } else if (event?.target?.name === "website") {
      if (
        !event?.target?.value.startsWith("http://") &&
        !event?.target?.value.startsWith("https://")
      ) {
        return;
      }

      // Remove any leading "http://" or "https://"
      value = event?.target?.value?.replace(/^https?:\/\//, "");

      // Ensure the value always starts with "https://"
      value = "https://" + value;
    } else {
      value = event?.target?.value;
    }

    setPartnerState((prevState) => ({
      ...prevState,
      [event?.target?.name]: {
        value,
        error: false,
      },
    }));
  };

  const handleLastStep = async () => {
    // Copy state for submission
    let submitState = { ...partnerState };

    // Copy head office details if same addresses is set
    if (submitState?.sameAddresses) {
      submitState.postalCountry = submitState.headOfficeCountry;
      submitState.postalStreetName = submitState.headOfficeStreetName;
      submitState.postalSuburb = submitState.headOfficeSuburb;
      submitState.postalState = submitState.headOfficeState;
      submitState.postalPostcode = submitState.headOfficePostcode;
    }

    // Copy admin if same relationship manager is set
    if (submitState?.sameRelManager) {
      submitState.relManagerFirstName = submitState.adminFirstName;
      submitState.relManagerLastName = submitState.adminLastName;
      submitState.relManagerEmail = submitState.adminEmail;
      submitState.relManagerPhone = submitState.adminPhone;
    }

    // Copy admin details if same business manager is set
    if (submitState?.sameBusManager) {
      submitState.busManagerFirstName = submitState.adminFirstName;
      submitState.busManagerLastName = submitState.adminLastName;
      submitState.busManagerEmail = submitState.adminEmail;
      submitState.busManagerPhone = submitState.adminPhone;
    }

    // Copy admin details if same representative is set
    if (submitState?.sameRep) {
      submitState.repFirstName = submitState.adminFirstName;
      submitState.repLastName = submitState.adminLastName;
      submitState.repEmail = submitState.adminEmail;
      submitState.repPhone = submitState.adminPhone;
      submitState.repRole = submitState.adminRole;
      submitState.repAddressCountry = submitState.postalCountry;
      submitState.repAddressStreetName = submitState.postalStreetName;
      submitState.repAddressSuburb = submitState.postalSuburb;
      submitState.repAddressState = submitState.postalState;
      submitState.repAddressPostcode = submitState.postalPostcode;
    }

    // Reduce partnerState to array
    let data = Object.keys(submitState).reduce((acc, key) => {
      acc[key] = submitState[key].value;
      return acc;
    }, {});

    data = {
      ...data,
      sameAddresses: submitState.sameAddresses,
      [DashboardOptions.filter(
        (option) => option.route === data?.dashboardType
      )[0]?.check]: true,
    };

    // CAP referral
    data = {
      ...data,
      CAPReferralCode: capReferral?.code,
      CAPReferralCertBundle: capReferral?.certBundle,
      CAPReferralLinkToReferrer: capReferral?.linkToReferrer,
    };

    // Submit to backend
    let result = await submitPartnerAgreement(data);

    if (!!result?.pendingPartner) {
      handleNextStep();
    } else if (result?.error?.code !== "EMAIL_EXISTS") {
      setCurrentStep(4);
      setPartnerState({
        ...partnerState,
        adminEmail: {
          ...partnerState.adminEmail,
          error: true,
          errorMsg: result?.error?.message,
        },
      });
    } else {
      return (
        {
          error: result?.error,
        } || {
          error: { code: "UNKNOWN", message: "Unknown error" },
        }
      );
    }
  };

  const handleNextStep = () =>
    setCurrentStep((prevStep) => Math.min(prevStep + 1, STEPS.length));

  const handlePrevStep = () =>
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));

  const handleReset = () => {
    setCurrentStep(0);
    setIsConfirmed(false);
  };

  const handleUpdate = () => setIsConfirmed(true);

  // CAP referral
  useEffect(() => {
    // check storage
    if (!!sessionStorage.getItem("CAP_REFERRAL_CODE")) {
      try {
        setCapReferral({
          code: JSON.parse(sessionStorage.getItem("CAP_REFERRAL_CODE")),
          certBundle: JSON.parse(
            sessionStorage.getItem("CAP_REFERRAL_CERT_BUNDLE")
          ),
          linkToReferrer: JSON.parse(
            sessionStorage.getItem("CAP_REFERRAL_LINK_TO_REFERRER")
          ),
        });
        sessionStorage.removeItem("CAP_REFERRAL_CODE");
        sessionStorage.removeItem("CAP_REFERRAL_CERT_BUNDLE");
        sessionStorage.removeItem("CAP_REFERRAL_LINK_TO_REFERRER");
      } catch (error) {
        console.error(error);
      }
    }
    // check location state
    if (state?.referral) {
      setCapReferral(state?.referral);
    }
  }, []);

  useEffect(() => {
    if (!!state?.partnerState) {
      setPartnerState((prevState) => ({
        ...prevState,
        ...state?.partnerState,
      }));
      setIsConfirmed(true);
    }

    if (!!state?.type) {
      setPartnerState((prevState) => ({
        ...prevState,
        dashboardType: {
          ...prevState.dashboardType,
          value: state?.type,
        },
      }));
    }
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [currentStep]);

  return (
    <div className="content-body">
      <div className="form-container flex flex-col gap-8 tablet:flex-row tablet:gap-18">
        {isConfirmed ? (
          <PartnerFormStepLayout
            steps={STEPS}
            currentStep={currentStep}
            partnerState={partnerState}
            handleReset={handleReset}
          >
            {
              [
                <PartnerFormTypeStep
                  partnerState={partnerState}
                  setPartnerState={setPartnerState}
                  handleInput={handleInput}
                  handleNextStep={handleNextStep}
                />,
                <PartnerFormLegalStep
                  partnerState={partnerState}
                  setPartnerState={setPartnerState}
                  handleInput={handleInput}
                  handleNextStep={handleNextStep}
                  handlePrevStep={handlePrevStep}
                />,
                <PartnerFormInfoStep
                  partnerState={partnerState}
                  setPartnerState={setPartnerState}
                  handleInput={handleInput}
                  handleNextStep={handleNextStep}
                  handlePrevStep={handlePrevStep}
                />,
                <PartnerFormAddressStep
                  partnerState={partnerState}
                  setPartnerState={setPartnerState}
                  handleInput={handleInput}
                  handleNextStep={handleNextStep}
                  handlePrevStep={handlePrevStep}
                />,
                <PartnerFormAdminStep
                  partnerState={partnerState}
                  setPartnerState={setPartnerState}
                  handleInput={handleInput}
                  handleNextStep={handleNextStep}
                  handlePrevStep={handlePrevStep}
                />,
                <PartnerFormContactsStep
                  partnerState={partnerState}
                  setPartnerState={setPartnerState}
                  handleInput={handleInput}
                  handleNextStep={handleLastStep}
                  handlePrevStep={handlePrevStep}
                />,
                <PartnerFormCompletedStep />,
              ][currentStep]
            }
          </PartnerFormStepLayout>
        ) : (
          <PartnerFormABNStep
            partnerState={partnerState}
            setPartnerState={setPartnerState}
            handleInput={handleInput}
            handleUpdate={handleUpdate}
          />
        )}
      </div>
    </div>
  );
}
