import { Spinner } from "flowbite-react";
import { SpinnerTheme } from "themes";

export function FlowbiteSpinner({ className, color = "blue", size = "md" }) {
  return (
    <Spinner
      className={className}
      color={color}
      size={size}
      theme={SpinnerTheme}
    />
  );
}
