import { useEffect, useMemo, useRef, useState } from "react";

import { useWindowSize } from "contexts";

export function BenefitsSliderHero({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);

  const { wWidth } = useWindowSize();

  const sliderRef = useRef(null);

  const isMobile = useMemo(() => wWidth < 768, [wWidth]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % (data.length + 1));
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentIndex === data.length) {
      setTimeout(() => {
        setCurrentIndex(0);
      }, 1000);
    }
  }, [currentIndex]);

  useEffect(() => {
    const element = sliderRef.current;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSliderWidth(entry.contentRect.width);
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [sliderRef]);

  const firstIndustry = data[0];
  const totalSize = isMobile
    ? data.length * 32 * 2
    : data.length * sliderWidth * 2;

  return (
    <ul
      ref={sliderRef}
      className="flex h-8 flex-col gap-[100%] self-stretch overflow-hidden md:flex-row"
    >
      <li
        key={firstIndustry.title}
        className={`flex size-full flex-shrink-0 flex-row items-center justify-center gap-4 md:justify-start ${currentIndex === 0 ? "transition-none" : "carousel-transition"} ${currentIndex === data.length ? "opacity-100" : "opacity-0"}`}
        style={{
          transform: isMobile
            ? `translateY(calc(${-totalSize}px + ${currentIndex * 200}%))`
            : `translateX(calc(${-totalSize}px + ${currentIndex * 200}%))`,
        }}
      >
        <firstIndustry.icon className="size-8 text-[#48A6CD]" />
        <h4 className="h4 text-white">{firstIndustry.title}</h4>
      </li>

      {data
        .map((industry, index) => (
          <li
            key={industry.title}
            className={`flex size-full flex-shrink-0 flex-row items-center justify-center gap-4 md:justify-start ${currentIndex === 0 ? "transition-none" : "carousel-transition"} ${index === currentIndex ? "opacity-100" : "opacity-0"}`}
            style={{
              transform: isMobile
                ? `translateY(calc(${-totalSize}px + ${currentIndex * 200}%))`
                : `translateX(calc(${-totalSize}px + ${currentIndex * 200}%))`,
            }}
          >
            <industry.icon className="size-8 text-[#48A6CD]" />
            <h4 className="h4 text-white">{industry.title}</h4>
          </li>
        ))
        .reverse()}
    </ul>
  );
}
