import { GiUnplugged } from "react-icons/gi";

export function MaintenancePage() {
  return (
    <section className="content-body">
      <div className="flex h-fit flex-col gap-16 rounded-lg border border-gray-200 bg-white p-32 laptop:flex-row laptop:gap-[72px] laptop:p-[48px] desktop:-mt-[55px]">
        <div className="flex w-full flex-col items-center gap-8">
          <div className="flex h-24 w-24 items-center justify-center rounded-full bg-gray-100">
            <GiUnplugged className="h-[60px] w-[60px] text-gray-900" />
          </div>
          <span className="text-center text-4xl font-semibold text-black">
            Maintenance mode
          </span>

          <span className="flex text-center text-sm font-normal text-gray-600 tablet:w-[75%]">
            We are working to improve your experience using our app. Please
            check back later.
          </span>
        </div>
      </div>
    </section>
  );
}
