import { useEffect, useState } from "react";

import {
  BackButton,
  FlowbiteTextInput,
  Select,
  SubmitButton,
} from "components";
import { Alert } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import {
  handleEnterKeyFormSubmission,
  validateABN,
  validateEmpty,
} from "services";

const COUNTRY_OPTIONS = ["Australia"];

export function PartnerFormLegalStep({
  partnerState,
  setPartnerState,
  handleInput = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [countryValue, setCountryValue] = useState(
    !!partnerState?.country?.value
      ? COUNTRY_OPTIONS.indexOf(partnerState.country.value)
      : 0
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.hasOwnProperty(key)) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    // TODO: Country checking once more than Australia is added
    if (
      !validateEmpty(submitState?.ABN?.value) ||
      !validateABN(submitState?.ABN?.value)
    ) {
      submitState.ABN.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.entityName?.value)) {
      submitState.entityName.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.entityType?.value)) {
      submitState.entityType.error = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitState?.preferredName?.value)) {
      submitState.preferredName.error = true;
      isInvalidField = true;
    }
    if (!!isInvalidField) {
      setErr({
        type: "invalid",
        msg: "You have entered an invalid field. Please try again.",
      });
      setPartnerState(submitState);
      return;
    }

    // Goto next step now
    setIsLoading(false);
    handleNextStep();
  };

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      country: {
        ...prevState.country,
        value: COUNTRY_OPTIONS[countryValue],
        error: false,
      },
    }));
  }, [countryValue]);

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
  }, [partnerState]);

  return (
    <>
      {!!err?.msg && (
        <Alert
          color="failure"
          icon={BiSolidErrorAlt}
          onDismiss={() => setErr(null)}
        >
          {err?.msg}
        </Alert>
      )}

      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Country of Registration
            </span>

            <Select
              disabled={isLoading}
              options={COUNTRY_OPTIONS}
              value={countryValue}
              setValue={setCountryValue}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">ABN</span>

            <FlowbiteTextInput
              autocomplete="off"
              color={partnerState?.ABN?.error ? "failure" : "gray"}
              disabled
              name="ABN"
              // placeholder="Enter your ABN"
              type="tel"
              value={partnerState?.ABN?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Legal Entity Name
            </span>

            <FlowbiteTextInput
              autocomplete="off"
              color={partnerState?.entityName?.error ? "failure" : "gray"}
              disabled
              name="entityName"
              // placeholder="Enter your legal entity name"
              type="text"
              value={partnerState?.entityName?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Entity Type
            </span>

            <FlowbiteTextInput
              autocomplete="off"
              color={partnerState?.entityType?.error ? "failure" : "gray"}
              disabled
              name="entityType"
              // placeholder="Enter your entity type"
              type="text"
              value={partnerState?.entityType?.value
                ?.split(" ")
                ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                ?.join(" ")}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-sm font-medium text-gray-600">
              Preferred Entity Name
            </span>

            <FlowbiteTextInput
              autocomplete="off"
              color={partnerState?.preferredName?.error ? "failure" : "gray"}
              disabled={isLoading}
              name="preferredName"
              placeholder="Enter your preferred entity name"
              required // added required field
              type="text"
              value={partnerState?.preferredName?.value}
              onKeyDown={(event) =>
                handleEnterKeyFormSubmission(event, () => handleSubmit(event))
              }
              onInput={handleInput}
            />
          </div>
        </div>

        <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
          <BackButton isLoading={isLoading} onClick={handlePrevStep}>
            Back
          </BackButton>

          <SubmitButton variant="small" type="submit" isLoading={isLoading}>
            Continue
          </SubmitButton>
        </div>
      </form>
    </>
  );
}
