const BACKGROUNDS = [
  "bg-gray-500",
  "bg-gray-600",
  "bg-gray-700",
  "bg-gray-800",
  "bg-gray-900",
  "bg-gray-950",
  "bg-[#01040E]",
  "bg-black",
];

export function BenefitCard({
  icon: Icon,
  index = 0,
  title = "",
  description = "",
}) {
  return (
    <div
      className={`w-full md:w-56 xl:w-64 md:h-[360px] xl:h-[400px] flex h-fit flex-shrink-0 flex-col gap-4 rounded-2xl p-6 text-white shadow-lg ${BACKGROUNDS[Math.min(index, BACKGROUNDS.length - 1)]}`}
    >
      <Icon className="size-10 flex-shrink-0" />
      <h4 className="h4 font-medium">{title}</h4>
      <p className="body-md !text-gray-200">{description}</p>
    </div>
  );
}
