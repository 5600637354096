import { Footer } from 'layouts/footer';
import { Header } from 'layouts/header';

export function LayoutWrapper({ children }) {
  return (
    <>
      <Header />

      <main className="bg-figure flex min-h-screen flex-grow flex-col items-center justify-center px-5 py-40">
        <div className="main-content relative flex flex-grow flex-col items-center justify-center">
          {children}
        </div>
      </main>

      <Footer />
    </>
  );
}
