import { ButtonWithRef } from "components";
import { useNavigate } from "react-router-dom";
import {
  getLandingPage,
  openZEWidget,
  scrollToElementSmoothly,
} from "services";

import { NavBarMenuItems as items } from "./navbar-menu-items";

export function NavbarMenu({ close, variant = "primary" }) {
  const navigate = useNavigate();

  return (
    <>
      <div
        id="cybercert-navbar-mobile-menu"
        className="flex w-full"
        style={{ WebkitTapHighlightColor: "transparent" }}
      >
        <ul className="flex flex-col gap-sm-c w-full">
          <li>
            {variant === "partner" ? (
              <ButtonWithRef
                variant="blank"
                className="w-full hover:bg-gray-100 hover:shadow focus:ring-4 focus:ring-blue-300"
                onClick={() => {
                  close();
                  scrollToElementSmoothly("become-partner", 500);
                }}
              >
                <p className="btn-text py-3">Get Started</p>
              </ButtonWithRef>
            ) : (
              <ButtonWithRef
                variant="blank"
                className="w-full hover:bg-gray-100 hover:shadow focus:ring-4 focus:ring-blue-300"
                onClick={() => {
                  close();
                  openZEWidget();
                }}
              >
                {
                  items.find((obj) => obj.id === "cybercert-menu-contact")
                    .content
                }
              </ButtonWithRef>
            )}
          </li>
          <li>
            <ButtonWithRef
              className="w-full"
              onClick={() => {
                close();
                window.location.href = `${getLandingPage()}/login`;
              }}
            >
              Login
            </ButtonWithRef>
          </li>
        </ul>
      </div>
    </>
  );
}
