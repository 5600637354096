import {
  useEffect,
  useState,
} from 'react';

import {
  BackButton,
  FlowbiteTextInput,
  Select,
  SubmitButton,
} from 'components';
import { Alert } from 'flowbite-react';
import { BiSolidErrorAlt } from 'react-icons/bi';
import {
  handleEnterKeyFormSubmission,
  validateEmail,
  validateEmpty,
  validatePhoneNumber,
  validatePostCode,
} from 'services';

const RADIO_OPTIONS = ["same", "new"];

const COUNTRY_OPTIONS = ["Australia"];

const STATE_OPTIONS = [
  "Select State",
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
];

export function PartnerFormContactsStep({
  partnerState,
  setPartnerState,
  handleInput = () => {},
  handleNextStep = async () => {},
  handlePrevStep = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [repCountryValue, setRepCountryValue] = useState(
    !!partnerState?.repCountry?.value
      ? COUNTRY_OPTIONS.indexOf(partnerState?.repCountry?.value)
      : 0
  );
  const [repStateValue, setRepStateValue] = useState(
    !!partnerState?.repAddressState?.value
      ? STATE_OPTIONS.indexOf(partnerState?.repAddressState?.value)
      : 0
  );

  const handleChange = (event) => {
    setPartnerState((prevState) => ({
      ...prevState,
      [event?.target?.name]: !RADIO_OPTIONS.indexOf(event.target.value),
    }));
    setErr(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim values in state
    let submitState = { ...partnerState };
    for (const key in submitState) {
      if (submitState?.[key]?.hasOwnProperty("value")) {
        submitState[key] = {
          ...submitState?.[key],
          value: submitState?.[key]?.value?.trim(),
        };
      }
    }

    // Check for invalid fields
    let isInvalidField = false;
    let isNotSelected = false;

    if (!submitState?.sameRelManager) {
      if (!validateEmpty(submitState?.relManagerFirstName?.value)) {
        submitState.relManagerFirstName.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.relManagerLastName?.value)) {
        submitState.relManagerLastName.error = true;
        isInvalidField = true;
      }
      if (
        !validateEmpty(submitState?.relManagerEmail?.value) ||
        !validateEmail(submitState?.relManagerEmail?.value)
      ) {
        submitState.relManagerEmail.error = true;
        isInvalidField = true;
      }
      if (
        !validateEmpty(submitState?.relManagerPhone?.value) ||
        !validatePhoneNumber(submitState?.relManagerPhone?.value)
      ) {
        submitState.relManagerPhone.error = true;
        isInvalidField = true;
      }
    }

    if (!submitState?.sameBusManager) {
      if (!validateEmpty(submitState?.busManagerFirstName?.value)) {
        submitState.busManagerFirstName.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.busManagerLastName?.value)) {
        submitState.busManagerLastName.error = true;
        isInvalidField = true;
      }
      if (
        !validateEmpty(submitState?.busManagerEmail?.value) ||
        !validateEmail(submitState?.busManagerEmail?.value)
      ) {
        submitState.busManagerEmail.error = true;
        isInvalidField = true;
      }
      if (
        !validateEmpty(submitState?.busManagerPhone?.value) ||
        !validatePhoneNumber(submitState?.busManagerPhone?.value)
      ) {
        submitState.busManagerPhone.error = true;
        isInvalidField = true;
      }
    }

    if (!submitState?.sameRep) {
      if (!validateEmpty(submitState?.repFirstName?.value)) {
        submitState.repFirstName.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.repLastName?.value)) {
        submitState.repLastName.error = true;
        isInvalidField = true;
      }
      if (
        !validateEmpty(submitState?.repEmail?.value) ||
        !validateEmail(submitState?.repEmail?.value)
      ) {
        submitState.repEmail.error = true;
        isInvalidField = true;
      }
      if (
        !validateEmpty(submitState?.repPhone?.value) ||
        !validatePhoneNumber(submitState?.repPhone?.value)
      ) {
        submitState.repPhone.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.repRole?.value)) {
        submitState.repRole.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.repAddressStreetName?.value)) {
        submitState.repAddressStreetName.error = true;
        isInvalidField = true;
      }
      if (!validateEmpty(submitState?.repAddressSuburb?.value)) {
        submitState.repAddressSuburb.error = true;
        isInvalidField = true;
      }
      if (partnerState?.repAddressState?.value === STATE_OPTIONS[0]) {
        submitState.repAddressState.error = true;
        isNotSelected = true;
      }
      if (
        !validateEmpty(submitState?.repAddressPostcode?.value) ||
        !validatePostCode(submitState?.repAddressPostcode?.value)
      ) {
        submitState.repAddressPostcode.error = true;
        isInvalidField = true;
      }
    }
    if (isInvalidField) {
      setErr({
        type: "invalid",
        msg: "You have entered an invalid field. Please try again.",
      });
      setPartnerState(submitState);
      return;
    } else if (isNotSelected) {
      setErr({
        type: "non-selection",
        msg: "You have not selected an option.",
      });
      setPartnerState(submitState);
      return;
    }

    // Goto next step now
    setIsLoading(true);

    let result = await handleNextStep();

    if (!!result?.error) {
      setErr({
        type: "invalid",
        msg: result?.error?.message,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      repAddressState: {
        ...prevState.repAddressState,
        value: STATE_OPTIONS[repStateValue],
        error: false,
      },
    }));
  }, [repStateValue]);

  useEffect(() => {
    setPartnerState((prevState) => ({
      ...prevState,
      repAddressCountry: {
        ...prevState.repAddressCountry,
        value: COUNTRY_OPTIONS[repCountryValue],
        error: false,
      },
    }));
  }, [repCountryValue]);

  useEffect(() => {
    setPartnerState((prevState) => {
      prevState.relManagerFirstName.error = false;
      prevState.relManagerLastName.error = false;
      prevState.relManagerEmail.error = false;
      prevState.relManagerPhone.error = false;
      return prevState;
    });
  }, [partnerState?.sameRelManager]);

  useEffect(() => {
    setPartnerState((prevState) => {
      prevState.busManagerFirstName.error = false;
      prevState.busManagerLastName.error = false;
      prevState.busManagerEmail.error = false;
      prevState.busManagerPhone.error = false;
      return prevState;
    });
  }, [partnerState?.sameBusManager]);

  useEffect(() => {
    setPartnerState((prevState) => {
      prevState.repFirstName.error = false;
      prevState.repLastName.error = false;
      prevState.repEmail.error = false;
      prevState.repPhone.error = false;
      prevState.repRole.error = false;
      prevState.repAddressCountry.error = false;
      prevState.repAddressStreetName.error = false;
      prevState.repAddressSuburb.error = false;
      prevState.repAddressState.error = false;
      prevState.repAddressPostcode.error = false;
      return prevState;
    });
  }, [partnerState?.sameRep]);

  useEffect(() => {
    if (Object?.values(partnerState)?.every((obj) => !obj.error)) {
      setErr(null);
    }
  }, [partnerState]);

  return (
    <>
      {!!err?.msg && (
        <Alert
          color="red"
          icon={BiSolidErrorAlt}
          onDismiss={() => setErr(null)}
        >
          {err?.msg}
        </Alert>
      )}

      <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
        <span className="text-base font-medium text-black">
          Relationship Manager
        </span>

        <div className="flex gap-8">
          <div className="flex gap-4">
            <input
              checked={partnerState?.sameRelManager}
              disabled={isLoading}
              required
              name="sameRelManager"
              type="radio"
              value={RADIO_OPTIONS[0]}
              onChange={handleChange}
            />
            <span className="text-sm font-medium leading-none text-gray-600">
              Same as Admin User
            </span>
          </div>

          <div className="flex gap-4">
            <input
              checked={!partnerState?.sameRelManager}
              disabled={isLoading}
              name="sameRelManager"
              required
              type="radio"
              value={RADIO_OPTIONS[1]}
              onChange={handleChange}
            />
            <span className="text-sm font-medium leading-none text-gray-600">
              New Personnel
            </span>
          </div>
        </div>

        {!partnerState?.sameRelManager && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">
                Personnel Info
              </span>

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.relManagerFirstName?.error
                      ? "failure"
                      : "gray"
                  }
                  disabled={isLoading}
                  name="relManagerFirstName"
                  required
                  placeholder="First Name"
                  type="text"
                  value={partnerState?.relManagerFirstName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.relManagerLastName?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="relManagerLastName"
                  required
                  placeholder="Last Name"
                  type="text"
                  value={partnerState?.relManagerLastName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">
                Contact Details
              </span>

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.relManagerEmail?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="relManagerEmail"
                  placeholder="Email"
                  required
                  type="email"
                  value={partnerState?.relManagerEmail?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.relManagerPhone?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="relManagerPhone"
                  placeholder="Phone"
                  required
                  type="tel"
                  value={partnerState?.relManagerPhone?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />
              </div>
            </div>
          </div>
        )}

        <span className="text-base font-medium text-black">
          Business Development Manager
        </span>

        <div className="flex gap-8">
          <div className="flex gap-4">
            <input
              checked={partnerState?.sameBusManager}
              disabled={isLoading}
              name="sameBusManager"
              required
              type="radio"
              value={RADIO_OPTIONS[0]}
              onChange={handleChange}
            />
            <span className="text-sm font-medium leading-none text-gray-600">
              Same as Admin User
            </span>
          </div>

          <div className="flex gap-4">
            <input
              checked={!partnerState?.sameBusManager}
              disabled={isLoading}
              name="sameBusManager"
              required
              type="radio"
              value={RADIO_OPTIONS[1]}
              onChange={handleChange}
            />
            <span className="text-sm font-medium leading-none text-gray-600">
              New Personnel
            </span>
          </div>
        </div>

        {!partnerState?.sameBusManager && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">
                Personnel Info
              </span>

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.busManagerFirstName?.error
                      ? "failure"
                      : "gray"
                  }
                  disabled={isLoading}
                  name="busManagerFirstName"
                  required
                  placeholder="First Name"
                  type="text"
                  value={partnerState?.busManagerFirstName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.busManagerLastName?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="busManagerLastName"
                  placeholder="Last Name"
                  required
                  type="text"
                  value={partnerState?.busManagerLastName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">
                Contact Details
              </span>

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.busManagerEmail?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="busManagerEmail"
                  required
                  placeholder="Email"
                  type="email"
                  value={partnerState?.busManagerEmail?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.busManagerPhone?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="busManagerPhone"
                  placeholder="Phone"
                  required
                  type="tel"
                  value={partnerState?.busManagerPhone?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />
              </div>
            </div>
          </div>
        )}

        <span className="text-base font-medium text-black">
          Representative and Address for Notices
        </span>

        <div className="flex gap-8">
          <div className="flex gap-4">
            <input
              checked={partnerState?.sameRep}
              disabled={isLoading}
              name="sameRep"
              type="radio"
              value={RADIO_OPTIONS[0]}
              required
              onChange={handleChange}
            />
            <span className="text-sm font-medium leading-none text-gray-600">
              Same as Admin User
            </span>
          </div>

          <div className="flex gap-4">
            <input
              checked={!partnerState?.sameRep}
              disabled={isLoading}
              name="sameRep"
              type="radio"
              required
              value={RADIO_OPTIONS[1]}
              onChange={handleChange}
            />
            <span className="text-sm font-medium leading-none text-gray-600">
              New Personnel
            </span>
          </div>
        </div>

        {!partnerState?.sameRep && (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">
                Personnel Info
              </span>

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={partnerState?.repFirstName?.error ? "failure" : "gray"}
                  disabled={isLoading}
                  name="repFirstName"
                  required
                  placeholder="First Name"
                  type="text"
                  value={partnerState?.repFirstName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={partnerState?.repLastName?.error ? "failure" : "gray"}
                  disabled={isLoading}
                  name="repLastName"
                  placeholder="Last Name"
                  required
                  type="text"
                  value={partnerState?.repLastName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">
                Contact Details
              </span>

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={partnerState?.repEmail?.error ? "failure" : "gray"}
                  disabled={isLoading}
                  name="repEmail"
                  placeholder="Email"
                  required
                  type="email"
                  value={partnerState?.repEmail?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={partnerState?.repPhone?.error ? "failure" : "gray"}
                  disabled={isLoading}
                  name="repPhone"
                  placeholder="Phone"
                  required
                  type="tel"
                  value={partnerState?.repPhone?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">Role</span>

              <FlowbiteTextInput
                autocomplete="off"
                color={partnerState?.repRole?.error ? "failure" : "gray"}
                disabled={isLoading}
                name="repRole"
                placeholder="Enter your role"
                required
                type="text"
                value={partnerState?.repRole?.value}
                onKeyDown={(event) =>
                  handleEnterKeyFormSubmission(event, () => handleSubmit(event))
                }
                onInput={handleInput}
              />
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-600">Address</span>

              <Select
                className="text-sm"
                disabled={isLoading}
                options={COUNTRY_OPTIONS}
                value={repCountryValue}
                setValue={setRepCountryValue}
              />

              <div className="flex gap-2">
                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.repAddressStreetName?.error
                      ? "failure"
                      : "gray"
                  }
                  disabled={isLoading}
                  name="repAddressStreetName"
                  placeholder="Street Address"
                  type="text"
                  value={partnerState?.repAddressStreetName?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                  required // made this a required field
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.repAddressSuburb?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="repAddressSuburb"
                  placeholder="Suburb"
                  type="text"
                  value={partnerState?.repAddressSuburb?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                  required // made this a required field
                />
              </div>

              <div className="flex gap-2">
                <Select
                  className="flex-1 text-sm"
                  disabled={isLoading}
                  error={partnerState.repAddressState.error}
                  options={STATE_OPTIONS}
                  value={repStateValue}
                  setValue={setRepStateValue}
                />

                <FlowbiteTextInput
                  autocomplete="off"
                  className="flex-1"
                  color={
                    partnerState?.repAddressPostcode?.error ? "failure" : "gray"
                  }
                  disabled={isLoading}
                  name="repAddressPostcode"
                  placeholder="Postcode"
                  type="tel"
                  value={partnerState?.repAddressPostcode?.value}
                  onKeyDown={(event) =>
                    handleEnterKeyFormSubmission(event, () =>
                      handleSubmit(event)
                    )
                  }
                  onInput={handleInput}
                  required // made this a required field
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center gap-4 tablet:flex-row tablet:justify-end">
          <BackButton isLoading={isLoading} onClick={handlePrevStep}>
            Back
          </BackButton>

          <SubmitButton variant="small" type="submit" isLoading={isLoading}>
            Submit
          </SubmitButton>
        </div>
      </form>
    </>
  );
}
