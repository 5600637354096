import {
  useEffect,
  useState,
} from 'react';

import { Hub } from 'aws-amplify';
import { encode as base64_encode } from 'base-64';
import { FlexSpinner } from 'components';
import {
  HomeLayoutWrapper,
  LandingRedirect,
  LayoutWrapper,
  PartnerWrapper,
} from 'layouts';
import {
  MaintenancePage,
  PageNotFound,
  PartnerFormPage,
  PartnerLogin,
  PartnerOffer,
  PartnerRegister,
  PartnersMspPage,
} from 'pages';
import queryString from 'query-string';
import {
  Route,
  Routes,
} from 'react-router';
import {
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  AuthService,
  getLandingPage,
  openZEWidget,
  scrollToElementSmoothly,
} from 'services';
import {
  DEFAULT_LANGUAGE,
  LANGUAGES,
  ROUTES,
} from 'variables';

const MAINTENANCE_MODE_ON = false;

export function Router() {
  const [isLoading, setIsLoading] = useState(false);

  const { search, state } = useLocation();

  async function ssoPostSignup(input = {}) {
    let { ABN, provider, userInvite, claimInfo } = input;
    setIsLoading(true);
    let result;
    if (!!userInvite?.invite) {
      result = await AuthService.postSignupWithSSOCheckUserInvite({
        ABN,
        provider,
        userInvite,
      });
    } else {
      result = await AuthService.postSignupWithSSO({ ABN, provider });
    }
    if (result?.error === "ACCOUNT_EXISTED") {
      window.location.href = `${getLandingPage()}?c=${encodeURIComponent(
        base64_encode(
          JSON.stringify({
            ...claimInfo,
            error: "ACCOUNT_EXISTED",
          })
        )
      )}`;
    }
    setIsLoading(false);
  }

  useEffect(() => {
    return Hub.listen("auth", ({ payload: { event, data } }) => {
      setIsLoading(true);
      switch (event) {
        case "customOAuthState":
          let oauthData;
          try {
            oauthData = JSON.parse(data);
          } catch (e) {
            console.error(e);
          }
          switch (oauthData?.name) {
            case "SSO_POST_SIGNUP":
              const { ABN, provider, userInvite, claimInfo } =
                oauthData.data || {};
              ssoPostSignup({ ABN, provider, userInvite, claimInfo });
              break;
            case "SSO_POST_SIGNIN":
              const { successUrl } = oauthData.data || {};
              AuthService.ssoPostSignin({ successUrl });
              // setLoadingMsg(
              //   "Sit tight while we prepare your account. It won't take long."
              // );
              break;
            default:
          }
          break;
        case "signOut":
        case "oAuthSignOut":
          try {
            let postSignOut = sessionStorage.getItem("POST_SIGNOUT");
            if (!!postSignOut) {
              sessionStorage.setItem("POST_SIGNOUT", "");
              sessionStorage.setItem("ACTION_SIGNIN", postSignOut);
            }
            window.location.reload();
          } catch (e) {
            console.error(e);
          }
          break;
        case "signIn":
        case "oAuthSignIn":
          // AuthService.postSignIn({});
          break;
        case "cognitoHostedUI":
        case "signIn_failure":
        case "cognitoHostedUI_failure":
        default:
          setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!!search) {
      const params = queryString.parse(search);
      if (params["zewidget"] === "1") {
        setTimeout(() => {
          openZEWidget();
        }, 1000);
      }
    }
  }, [search]);

  useEffect(() => {
    if (!!state?.hash) {
      scrollToElementSmoothly(state?.hash, 500);
    }
  }, [state]);

  useEffect(() => {
    try {
      let ssoSignIn = sessionStorage.getItem("ACTION_SIGNIN");
      if (!!ssoSignIn) {
        let { signInInput } = JSON.parse(ssoSignIn);
        sessionStorage.setItem("ACTION_SIGNIN", "");
        if (!!signInInput?.provider) {
          AuthService.signInWithSSO(signInInput);
        } else if (!!signInInput?.email && !!signInInput?.password) {
          AuthService.signIn(signInInput);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return isLoading ? (
    <FlexSpinner />
  ) : !!MAINTENANCE_MODE_ON ? (
    <Routes>
      {/************************ MAINTENANCE MODE ************************/}
      <Route
        path={ROUTES.SELF.MAINTENANCE}
        element={
          <LayoutWrapper>
            <MaintenancePage />
          </LayoutWrapper>
        }
      />

      <Route
        path={ROUTES.PAGE_NOT_FOUND}
        element={<Navigate to={ROUTES.SELF.MAINTENANCE} />}
      />
    </Routes>
  ) : (
    <Routes>
      {/************************** NORMAL MODE **************************/}
      {LANGUAGES.map((LANG, index) => (
        <Route key={index} path={LANG}>
          <Route path={ROUTES.SELF.ROOT} element={<LandingRedirect />} />

          <Route
            path={ROUTES.SELF.PARTNER_LOGIN}
            element={
              <LayoutWrapper>
                <PartnerLogin />
              </LayoutWrapper>
            }
          />

          <Route
            path={ROUTES.SELF.PARTNER_FORM}
            element={
              <LayoutWrapper>
                <PartnerFormPage />
              </LayoutWrapper>
            }
          />

          <Route
            path={ROUTES.SELF.PARTNER_INVITE}
            element={
              <LayoutWrapper>
                <PartnerRegister />
              </LayoutWrapper>
            }
          />

          <Route
            path={ROUTES.SELF.PARTNERS_MSP}
            element={
              <PartnerWrapper>
                <PartnersMspPage />
              </PartnerWrapper>
            }
          />

          <Route
            path={ROUTES.SELF.PARTNER_OFFER}
            element={
              <LayoutWrapper>
                <PartnerOffer />
              </LayoutWrapper>
            }
          />

          <Route
            path={ROUTES.PAGE_NOT_FOUND}
            element={
              <HomeLayoutWrapper>
                <PageNotFound />
              </HomeLayoutWrapper>
            }
          />
        </Route>
      ))}

      <Route
        path={ROUTES[404]}
        element={
          <HomeLayoutWrapper>
            <PageNotFound />
          </HomeLayoutWrapper>
        }
      />

      <Route path={ROUTES.PAGE_NOT_FOUND} element={<LanguageRedirect />} />
    </Routes>
  );
}

function LanguageRedirect() {
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = localStorage.getItem("LANG");

    // Skip static media files
    if (pathname.startsWith("/static/media/")) {
      return;
    }

    // Redirect to default language if no language in URL
    if (!lang) {
      localStorage.setItem("LANG", DEFAULT_LANGUAGE);
      navigate(`/${DEFAULT_LANGUAGE}${pathname}${search}`, {
        replace: true,
        state,
      });
    } else if (LANGUAGES.includes(lang)) {
      navigate(`/${lang}${pathname}${search}`, {
        replace: true,
        state,
      });
    }
  }, [pathname, search, state, navigate]);

  return <FlexSpinner />;
}
