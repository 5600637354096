import {
  forwardRef,
  useState,
} from 'react';

import {
  FlowbiteSpinner,
  OverlaySpinner,
} from 'components';

const STYLES = {
  blank: "",
  primary:
    "bg-gray-900 p-[14px] text-white outline-none enabled:hover:bg-gray-800 enabled:hover:shadow-md disabled:opacity-50 focus:ring-4 focus:ring-blue-300",
  partner:
    "bg-gray-900 p-[14px] text-white outline-none enabled:hover:bg-gray-800 enabled:hover:shadow-md disabled:opacity-50 focus:ring-4 focus:ring-blue-300",
  secondary:
    "bg-transparent p-[14px] text-gray-900 outline-none outline-1 -outline-offset-1 outline-gray-900 focus:outline-none focus:ring-4 focus:ring-blue-300 enabled:hover:outline-blue-300",
  social:
    "bg-transparent p-[14px] !text-xs text-gray-900 outline-none outline-1 -outline-offset-1 outline-gray-300 hover:outline-gray-600 focus:outline-none focus:ring-4 focus:ring-blue-300 tablet:text-sm",
  small:
    "w-full h-[38px] bg-gray-900 px-4 py-1.5 text-white outline-none focus:ring-4 focus:ring-blue-300 enabled:hover:bg-gray-800 disabled:opacity-50 tablet:w-fit tablet:px-12",
  disabled: "cursor-not-allowed opacity-50",
};

export const ButtonWithRef = forwardRef(
  (
    {
      children,
      className,
      disabled,
      style,
      type = "button",
      variant = "primary",
      onClick = async () => {},
    },
    ref
  ) => {
    const [internalLoading, setInternalLoading] = useState(false);

    const handleClick = async (event) => {
      setInternalLoading(true);

      await onClick(event);

      setInternalLoading(false);
    };

    return (
      <>
        <button
          disabled={internalLoading || disabled}
          className={`flex flex-row items-center justify-center gap-2 rounded-[10px]
            ${STYLES[variant]} 
            ${className}
            ${disabled ? STYLES["disabled"] : ""}`}
          ref={ref}
          style={style}
          type={type}
          onClick={handleClick}
        >
          {internalLoading && <FlowbiteSpinner />}
          <span
            className={`flex flex-row items-center gap-2 ${variant === "partner" ? "btn-text" : "text-sm font-medium"}`}
          >
            {children}
          </span>
        </button>
      </>
    );
  }
);

export function BackButton({ children, variant, isLoading, ...props }) {
  return (
    <button
      className="flex h-[38px] w-full flex-row items-center justify-center gap-2 rounded-[10px] border border-gray-900 px-4 py-1.5 text-lg font-semibold leading-normal text-gray-900 outline-none focus:ring-4 focus:ring-blue-300 enabled:hover:bg-gray-200 disabled:opacity-50 tablet:w-fit tablet:px-12"
      disabled={isLoading}
      {...props}
    >
      {/* {isLoading && <Spinner />} */}
      <span className="flex flex-row items-center gap-2">{children}</span>
    </button>
  );
}

export function SubmitButton({
  children,
  className,
  disabled,
  spinnerSize = "md",
  style,
  variant = "primary",
  isLoading,
  ...props
}) {
  return (
    <button
      className={`relative flex flex-row items-center justify-center gap-2 rounded-[10px] ${STYLES[variant]} ${className} ${disabled ? STYLES["disabled"] : ""}`}
      disabled={isLoading}
      style={style}
      {...props}
    >
      {isLoading && <OverlaySpinner />}
      <span
        className={`flex flex-row items-center gap-2 ${variant === "partner" ? "btn-text" : "text-sm font-medium"}`}
      >
        {children}
      </span>
    </button>
  );
}
