import { useRef, useState } from "react";

import { CyberCertLogo } from "assets";
import { ButtonWithRef } from "components";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import {
  openZEWidget,
  scrollToElementSmoothly,
  useScrollPosition,
} from "services";
import { ROUTES } from "variables";

import { NavbarMenu } from "./navbar-menu";

export function Navbar({ variant = "primary" }) {
  const [showNavbarMenu, setShowNavbarMenu] = useState(false);

  const hamburgerBtnRef = useRef(null);
  const navbarMenuRef = useRef(null);

  const toggleNavbarMenu = () => {
    setShowNavbarMenu(!showNavbarMenu);
  };

  const handleCloseNavbarMenu = () => {
    setShowNavbarMenu(false);
  };

  const scrollPosition = useScrollPosition();

  const navigate = useNavigate();

  const shouldShowLoginBtn = () => {
    const paths = window.location.pathname.split("/");
    const path = paths[paths.length - 1];
    return ![ROUTES.SELF.LOGIN, ROUTES.SELF.PARTNER_LOGIN].includes(path);
  };

  const shouldShowScrollEffect = () => {
    return ![
      "claim",
      "invite",
      "login",
      "partner-form",
      "partner-login",
      "partner-register",
      "reset",
      "test",
    ].some((route) => window?.location?.pathname?.split("/")?.includes(route));
  };

  return (
    <>
      <nav
        id="cybercert-navbar"
        className={`flex w-full flex-col items-center border-b border-gray-200 transition-all ease-in-out 
          ${
            scrollPosition > 0 || !shouldShowScrollEffect() || showNavbarMenu
              ? "border-b bg-white backdrop-blur-md bg-opacity-60"
              : "border-none bg-transparent"
          }
          ${variant === "partner" ? "font-inter" : ""}
        `}
      >
        <div
          className={`flex h-[75px] flex-row items-center justify-between 2xl:px-0 px-5 md:px-[4%] 2xl:max-w-[1300px] w-full mx-auto ${variant === "partner" && scrollPosition <= 0 ? "text-white" : "text-gray-900"}`}
        >
          <Link
            id="cybercert-navbar-logo"
            to={ROUTES.ROOT}
            className={`rounded-full hover:shadow focus:ring-4 focus:ring-blue-300 ${variant === "partner" && scrollPosition <= 0 ? " text-white hover:text-white" : "text-gray-900"}`}
          >
            <CyberCertLogo
              className={`
                ${variant === "partner" ? "md:w-36" : ""}
                ${showNavbarMenu && scrollPosition <= 0 ? "text-black" : ""}
                `}
            />
          </Link>

          <div className="hidden md:block">
            <div className="flex flex-row items-center justify-end gap-sm-c">
              <ul className="flex flex-row justify-end gap-sm-c">
                <li>
                  {variant === "partner" ? (
                    <button
                      className="flex items-center justify-between rounded-full hover:shadow focus:ring-4 focus:ring-blue-300"
                      onClick={() =>
                        scrollToElementSmoothly("become-partner", 500)
                      }
                    >
                      <p className="text-sm font-medium">Get Started</p>
                    </button>
                  ) : (
                    <button
                      className="focus:ring-blue-300d esktop:text-base flex items-center justify-between rounded-full px-3 py-2 text-sm hover:shadow focus:ring-4 font-semibold"
                      onClick={openZEWidget}
                    >
                      <p className="p xl:text-sm">Contact</p>
                    </button>
                  )}
                </li>
              </ul>

              {shouldShowLoginBtn() && (
                <ButtonWithRef
                  className="h-[35px] px-7"
                  variant="primary"
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  Login
                </ButtonWithRef>
              )}
            </div>
          </div>

          <div className="flex items-center md:hidden" ref={hamburgerBtnRef}>
            <NavbarHamburgerBtn
              btnRef={hamburgerBtnRef}
              toggle={toggleNavbarMenu}
              show={showNavbarMenu}
              variant={variant}
              scrollPosition={scrollPosition}
            />
          </div>
        </div>

        {showNavbarMenu === true && (
          <div
            className="flex border-b border-t w-full p-8 !bg-transparent"
            ref={navbarMenuRef}
          >
            <NavbarMenu close={handleCloseNavbarMenu} variant={variant} />
          </div>
        )}
      </nav>
    </>
  );
}

export function NavbarHamburgerBtn({ toggle, show, variant, scrollPosition }) {
  return (
    <>
      <button onClick={toggle} className="flex items-center">
        {show ? (
          <IoClose
            className={`size-6 
              ${variant === "partner" ? "md:hidden" : ""}              
              ${show ? "text-black" : "text-white"}
              `}
          />
        ) : (
          <HiMenu
            className={`size-6 
              ${scrollPosition > 0 ? "text-black" : "text-white"}
              `}
          />
        )}
      </button>
    </>
  );
}
