import { Spinner } from "flowbite-react";

export function OverlaySpinner({ className, size = "md", color = "blue" }) {
  return (
    <div
      className={`absolute bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center rounded-lg bg-inherit${!!className ? ` ${className}` : ""}`}
    >
      <Spinner size={size} color={color} />
    </div>
  );
}
