import { FlowbiteTextInput, OverlaySpinner } from "components";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import {
  filterNumberAndSpaceKeyPress,
  filterNumberAndSpacePaste,
  handleKeyDownSubmission,
} from "services";

export function PartnerRegisterVerify({
  isLoading,
  phone,
  handleInput,
  verifyPhoneNumber,
  err,
  submitRef,
}) {
  return (
    <div className="input-form flex w-full flex-col gap-4 tablet:mt-10">
      <div className="subtitle body-sm leading-[21px] text-gray-600">
        To get started, enter your mobile number.
      </div>

      {!!err?.msg && (
        <div className="body-sm font-medium text-red-600">{err.msg}</div>
      )}

      <FlowbiteTextInput
        disabled={isLoading}
        id="MOBILE"
        name="mobile"
        type="tel"
        icon={HiOutlineDeviceMobile}
        placeholder="Enter your mobile number"
        required
        value={phone}
        onChange={handleInput}
        onKeyPress={filterNumberAndSpaceKeyPress}
        onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
        onPaste={filterNumberAndSpacePaste}
        color={!!err?.msg ? "failure" : "primary"}
      />

      <button
        className="btn-primary px-4 py-2 text-sm font-medium tablet:w-fit tablet:self-end tablet:px-12"
        disabled={isLoading}
        ref={submitRef}
        type="button"
        onClick={verifyPhoneNumber}
      >
        {isLoading && <OverlaySpinner />}
        Verify
      </button>
    </div>
  );
}
