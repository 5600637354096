import { FlowbiteSpinner } from "components";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { getLandingDestination } from "services";

export function PartnerLogin() {
  const [loading, setLoading] = useState(true);
  const location = useLocation;

  useEffect(() => {
    window.location.href = `${getLandingDestination()}${location.search || ""}`;
    setLoading(false);
  }, []);

  return <FlowbiteSpinner />;
}
