import { useEffect } from 'react';

import { FlexSpinner } from 'components';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ROUTES } from 'variables';

export function LandingRedirect() {
  const { search } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/${ROUTES.SELF.PARTNERS_MSP.replace(":referralCode?", "")}${search || ""}`,
      {
        replace: true,
      }
    );
  }, []);

  return <FlexSpinner />;
}
