export const validateEmail = (email) => {
  const emailMatch =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (String(email).toLowerCase().match(emailMatch)) {
    return true;
  } else {
    return false;
  }
};

export const validateEmpty = (str) => {
  if (!!str) {
    return str?.length > 0;
  }
  return false;
};

export const validateABN = (str) => {
  const abnRegex = /^(?!(\d)\1{10})\d{11}$/;

  return abnRegex.test(str?.replace(/\s+/g, ""));
};

export const validateANZSIC = (str) => {
  // ANZSIC codes are usually 4-digit numeric codes
  const anzsicRegex = /^\d{4}$/;

  return anzsicRegex.test(str?.replace(/\s+/g, ""));
};

export const validateACN = (str) => {
  // Remove all whitespace
  const cleanedStr = str?.replace(/\s+/g, "");

  // Check if the cleaned string is exactly 9 digits
  if (!/^\d{9}$/.test(cleanedStr)) {
    return false;
  }

  // Calculate checksum
  const digits = cleanedStr.split("").map(Number);
  const weights = [8, 7, 6, 5, 4, 3, 2, 1];

  let sum = 0;
  for (let i = 0; i < weights.length; i++) {
    sum += digits[i] * weights[i];
  }

  const remainder = sum % 10;
  const checkDigit = (10 - remainder) % 10;

  return checkDigit === digits[8];
};

export const validatePhoneNumber = (str) => {
  if (!str) return false;

  // Remove spaces, dashes, and parentheses
  const cleanedStr = str.replace(/[\s\-()]+/g, "");

  // Regex to match various phone number formats
  const phoneRegex = /^(\+?\d{1,3})?\d{6,14}$/;

  return phoneRegex.test(cleanedStr);
};

export const validateMobile = (str) => {
  const phoneRegex = /^(02|04)\d{8}$/;

  return (
    phoneRegex.test(str?.replace(/\s+/g, "")) &&
    str?.replace(/\s+/g, "")?.length === 10
  );
};

export const validateURL = (str) => {
  const urlRegex =
    /^(https?|ftp):\/\/((([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-zA-Z0-9@:%._+~#=]*)*(\?[;&a-zA-Z0-9%._+~#=/-]*)?(#[a-zA-Z0-9@:%._+~#=-]*)?$/;
  return urlRegex.test(str);
};

export const validatePostCode = (str) => {
  const postCodeRegex = /^[0-9]{4}$/;

  return postCodeRegex.test(str?.replace(/\s+/g, ""));
};
