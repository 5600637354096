import { useLayoutEffect } from "react";

import { PartnerFooter } from "layouts/footer";
import { Header } from "layouts/header";

export function PartnerWrapper({ children }) {
  useLayoutEffect(() => {
    // Add inter family CSS class to the body when the component mounts
    document.body.classList.add("font-inter");

    // Remove inter family CSS class from the body when the component unmounts
    return () => {
      document.body.classList.remove("font-inter");
    };
  }, []);

  return (
    <>
      <Header variant="partner" />

      <main
        id="partner-page"
        className="flex min-h-screen flex-grow flex-col items-center justify-center"
      >
        {children}
      </main>

      <PartnerFooter />
    </>
  );
}
