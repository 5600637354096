import {
  Footer,
  Header,
} from 'layouts';

export function HomeLayoutWrapper({ children }) {
  return (
    <>
      <Header />

      <main className="flex min-h-screen flex-grow flex-col items-center justify-center">
        {children}
      </main>

      <Footer />
    </>
  );
}
